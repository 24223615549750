import React from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Box, Flex, Text } from "@chakra-ui/react"
import { ArrowBackIcon } from "@chakra-ui/icons"
import { Container } from "../components/Container"
import { Heading } from "../components/Heading"
import { Layout } from "../layouts/default"
import { LinkedButton } from "../components/Button"

// Sanity helpers
import PortableText from "../components/PortableText"
import { FormulationChallengeBlock } from "../components/Blocks/FormulationChallengeBlock"
import { DynamicLink } from "../components/DynamicLink"

function PaperTemplate({ data, pageContext }) {
  const { locale } = pageContext
  const { title, _rawAuthors, _rawAbstract, pdfUpload, externalLink, seo } =
    data.sanityPaper

  return (
    <Layout locale={locale}>
      <Helmet>
        <title>{title[locale]}</title>
        <meta name="keywords" content={seo ? seo.keywords : ""} />
        <meta name="description" content={seo ? seo.description : ""} />
      </Helmet>

      <Box height="500px" position="relative" mb={-24} bg="blue.100">
        <StaticImage
          src="../../images/science-header.jpg"
          alt=""
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            zIndex: 1,
          }}
        />
      </Box>

      <Container px={0}>
        {title && (
          <Flex
            flexDir={{ base: "column", lg: "row" }}
            justifyContent={{ lg: "space-between" }}
          >
            <Box
              bg="white"
              width={{ md: "50%" }}
              p={{ base: 4, md: 12 }}
              pb={0}
              pos="relative"
              zIndex={10}
            >
              <DynamicLink
                to="/useful-resources/scientific-papers"
                style={{ textDecoration: "none" }}
              >
                <Text mb={4} fontSize={14} color="brand.400" fontWeight="bold">
                  <ArrowBackIcon mr={2} />
                  Back to Scientific Papers
                </Text>
              </DynamicLink>
              <Heading fontSize="40px" mb={pdfUpload || externalLink ? 8 : 0}>
                {title[locale]}
              </Heading>
              {pdfUpload ? (
                <LinkedButton href={pdfUpload.asset.url}>
                  Download PDF
                </LinkedButton>
              ) : externalLink ? (
                <LinkedButton href={externalLink} isExternal>
                  Read online
                </LinkedButton>
              ) : null}
            </Box>
          </Flex>
        )}

        <Container bg="white">
          {_rawAbstract && (
            <Box mb={24}>
              <PortableText blocks={_rawAbstract} locale={locale} />
            </Box>
          )}

          {_rawAuthors && (
            <Box mb={24}>
              <PortableText blocks={_rawAuthors} locale={locale} />
            </Box>
          )}
        </Container>

        <Container pt={4} borderTopWidth={2} borderTopColor="gray.300">
          <FormulationChallengeBlock locale={locale} />
        </Container>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query PaperQuery($id: String!) {
    sanityPaper(id: { eq: $id }) {
      title {
        en
        fr
        es
        it
        de
        nl
        ko
        ja
      }
      seo {
        keywords
        description
      }
      slug {
        current
      }
      _rawAuthors
      _rawAbstract
      externalLink
      pdfUpload {
        asset {
          url
        }
      }
    }
  }
`

export default PaperTemplate
